/* RESET */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* General Styles */
body {
    margin: 0;
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
}

::selection {
    background: #6b00ff;
    color: white;
}

/* BookCall Section */
.bookcall {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    /* By default, no top padding for larger screens. Adjust if desired. */
}

/* Reserve space for navbar on SMALL screens (up to 480px width).
   Instead of 25vh, use 5rem for consistent top gap. */
@media (max-width: 480px) {
    .bookcall {
        padding-top: 0rem !important;
    }
}

/* Reserve space for navbar on MEDIUM screens (up to 768px width).
   Instead of 15vh, use 5rem for consistent top gap. */
@media (max-width: 768px) {
    .bookcall {
        padding-top: 0rem !important;
    }
}

@keyframes twinkle {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.bookcall-hero-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 4rem 2rem;
}

.glowing-planet {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;
    background: radial-gradient(circle, rgba(107, 0, 255, 0.5), rgba(0, 0, 0, 0));
    border-radius: 50%;
    filter: blur(50px);
}

.bookcall-hero-content {
    width: 100%;
}

.bookcall-hero-title {
    width: 100%;
    max-width: 1200px;
    margin: 3vw auto 2rem auto;
    padding: 0 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bookcall-hero-content h1 {
    font-size: 2.5rem;
    margin: 3rem 0;
}

.bookcall-hero-content p {
    color: #bbb;
    font-size: 1.125rem;
}

.bookcall-hero-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.bookcall-hero-header .tagline {
    color: #6b00ff;
    font-weight: bold;
}

.icon {
    color: #6b00ff;
    width: 20px;
    height: 20px;
}

/* Booking Container */
.booking-container {
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(107, 0, 255, 0.3);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
}

/* Widget Wrapper for Calendly */
.widget-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 62vh;
    /* Same as InlineWidget height */
    overflow: hidden;
}

.widget-wrapper iframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: #bbb;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-item .icon {
    width: 16px;
    height: 16px;
}

.to-whyus-button {
    background: linear-gradient(to right, #a78bfa, #06b6d4);
    border: none;
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
}

/* Responsive Design for Screens up to 768px width */
@media (max-width: 768px) {
    .bookcall-hero-content h1 {
        font-size: 2rem;
    }

    .features {
        flex-direction: column;
        gap: 1rem;
    }
}

/* Responsive Design for Smaller Screens up to 480px width */
@media (max-width: 480px) {
    .bookcall-hero-container {
        padding: 2rem 1rem;
    }

    .bookcall-hero-content h1 {
        font-size: 2rem;
        margin: 2rem 0;
    }

    .bookcall-hero-content p {
        font-size: 1rem;
    }

    .booking-container {
        padding: 1rem;
        max-width: 100%;
    }

    /* Adjust Calendly widget height if necessary */
    .widget-wrapper {
        height: 50vh;
        /* adjust height if needed */
    }

    .features {
        flex-direction: column;
        gap: 1rem;
    }

    .to-whyus-button {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
    }
}