/* Prevent scrolling on the entire page */
html,
body {
    margin: 0;
    padding: 0;
    background-color: black;
}

/* Hide scrollbars for the main container */
.main {
    width: 100vw;
    position: relative;
}

.main::-webkit-scrollbar {
    display: none;
}

.main {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.spline-resize {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    mask-image: linear-gradient(to top, transparent 0%, black 30%);
    -webkit-mask-image: linear-gradient(to top, transparent 0%, black 30%);
}

.spline-scene-home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.2);
    transform-origin: center;
}