.clients-section {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    margin-top: 5rem;
    overflow: hidden;
    /* Ensures everything stays within bounds */
}

.clients-title {
    font-weight: bold;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 1rem auto;
    padding: 0 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.carousel-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carousel-track {
    display: flex;
    gap: 2rem;
    animation: slide 20s linear infinite;

    &:hover {
        animation-play-state: paused;
    }
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 300px;
    border-radius: 10%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.carousel-card>a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.carousel-card>a>img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10%;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}