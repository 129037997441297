@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.landing {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    text-align: center;
}

.landing-elements {
    margin-top: 3rem;
    color: white;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.landing-title {
    z-index: 1;
    font-size: 9vw;
    text-shadow: 0 0 5px white,
        0 0 10px white,
        0 0 25px rgba(255, 255, 255, 0.5);
    font-family: "Funnel Display", sans-serif;
    word-wrap: break-word;
}

.landing-middle {
    z-index: 1;
    font-size: 2vw;
    font-family: "Lato", sans-serif;
    max-width: 80%;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;
}

.landing-button {
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    padding: 0.5rem 2rem;
    margin-top: 10rem;
    margin-bottom: -10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50px;
    font-family: "Funnel Display", sans-serif;
    font-weight: 700;
    font-size: 2vw;
    z-index: 1;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.landing-button:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #8b5cf6,
        0 0 40px #3b82f6,
        0 0 80px rgba(154, 0, 201, 0.8);
}

/* Breakpoints */
@media (max-width: 1200px) {
    .landing-title {
        font-size: 12vw;
    }

    .landing-middle {
        font-size: 3vw;
        padding: 0 2rem;
    }

    .landing-button {
        font-size: 3.5vw;
    }
}

@media (max-width: 992px) {
    .landing-title {
        font-size: 14vw;
    }

    .landing-middle {
        font-size: 3.5vw;
        padding: 0 1.5rem;
    }

    .landing-button {
        font-size: 4vw;
        padding: 0.6rem 1.5rem;
    }
}

@media (max-width: 768px) {
    .landing-title {
        font-size: 16vw;
    }

    .landing-middle {
        font-size: 4vw;
        padding: 0 1rem;
    }

    .landing-button {
        font-size: 4.5vw;
        padding: 0.8rem 1.2rem;
    }
}

@media (max-width: 576px) {
    .landing-title {
        font-size: 18vw;
    }

    .landing-middle {
        font-size: 4.5vw;
        padding: 0 0.5rem;
    }

    .landing-button {
        font-size: 5vw;
        padding: 1rem 1rem;
    }
}

@media (max-width: 400px) {
    .landing-title {
        font-size: 20vw;
    }

    .landing-middle {
        font-size: 5vw;
    }

    .landing-button {
        font-size: 5.5vw;
        padding: 1.2rem 1rem;
    }
}

/* ---------------------
   Powered By Section
---------------------- */

.powered-by {
    margin-top: -2rem;
    margin-bottom: 2rem;
    margin-left: 20.5rem;
    font-size: 1.1rem;
    color: #94a3b8;
    text-align: center;
    z-index: 2;
}

.powered-by:hover {
    opacity: 1;
    color: white;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.powered-by-logo {
    height: 40px;
    width: auto;
}

/* Adjust as needed for smaller screens */
@media (max-width: 768px) {
    .powered-by {
        right: 5%;
        bottom: 5%;
    }

    .powered-by-logo {
        height: 25px;
    }
}