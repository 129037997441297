.orbs-section {
    position: relative;
    width: 100%;
    min-height: 600px;
    padding: 2rem;
    box-sizing: border-box;
    /* Increase perspective and set the origin at the top (camera looking down) */
    perspective: 2000px;
    perspective-origin: 50% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.center-text {
    text-align: center;
    margin-bottom: 2rem;
}

.center-text h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: pre-line;
}

/* Enlarged orbit container with a slight tilt for depth */
.orbit-container {
    position: relative;
    width: 800px;
    height: 800px;
    margin-bottom: 2rem;
    transform-style: preserve-3d;
    animation: orbit-spin 20s linear infinite;
}

/* Parent container spins (with a tilt) */
@keyframes orbit-spin {
    0% {
        transform: rotateX(20deg) rotateY(0deg);
    }

    100% {
        transform: rotateX(20deg) rotateY(360deg);
    }
}

/* Position each orb along the orbit circle */
.orb {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px;
    /* centers the orb */
    transform-style: preserve-3d;
    transition: transform 0.5s ease, z-index 0.5s ease;
    transform: rotateY(var(--angle)) translateZ(400px);
}

/* This wrapper cancels the parent's Y-rotation */
.orb-counter {
    transform-style: preserve-3d;
    animation: counter-spin 20s linear infinite;
}

/* The orb’s inner element cancels its own static rotation so it always faces front */
.orb-inner {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background: radial-gradient(circle, #ff8f46, #ff6542);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: rotateY(calc(-1 * var(--angle)));
}

/* Keyframes to counter-rotate the parent's spin */
@keyframes counter-spin {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(-360deg);
    }
}

/* When an orb is active, remove it from the spinning container */
.orb.active {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 999;
}

/* Stop counter-rotation when active */
.orb.active .orb-counter {
    animation: none;
}

/* Buttons at the bottom */
.buttons-container {
    display: flex;
    gap: 1rem;
}

.orbit-button {
    background-color: #ff6542;
    border: none;
    color: #fff;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.orbit-button:hover {
    background-color: #ff8f46;
}

.back-button {
    margin-top: 0.5rem;
    background-color: #fff;
    border: none;
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    border-radius: 4px;
}