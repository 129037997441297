@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.card-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Light background */
    padding: 20px;
}

/* Main card container */
.card {
    z-index: 1000;
    background: #fff;
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Top image with curved bottom corners */
.card-image {
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    height: 350px;
    /* Adjust if needed */
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

/* Info section after the image */
.card-info {
    padding: 20px;
    color: black;
    text-align: center;
}

.card-brand {
    margin-bottom: 15px;
}

/* "Belhomme LAW" styling */
.brand-name {
    z-index: 1;
    font-size: 2vw;
    text-shadow: 0 0 5px white,
        0 0 10px white,
        0 0 25px rgba(255, 255, 255, 0.5);
    font-family: "Funnel Display", sans-serif;
    word-wrap: break-word;
}


.person-name {
    font-size: 1.4rem;
    margin: 5px 0 0 0;
    font-weight: 600;
}

.founder-title {
    font-size: 1rem;
    color: #555;
    margin: 2px 0;
}

.company-name {
    font-size: 0.95rem;
    color: #777;
    margin-bottom: 10px;
}

/* Specialty text */
.tagline {
    font-size: 0.95rem;
    color: #333;
    margin-bottom: 20px;
}

/* Contact list */
.contact-list {
    margin-bottom: 20px;
}

/* Each contact row: icon + text */
.contact-item {
    color: black;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
}

.contact-icon img {
    width: 50px;
    height: 50px;
    min-width: 32px;
    margin-right: 10px;
    background: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

/* Make sure links have no default color decoration */
.contact-item a {
    color: black;
    text-decoration: none;
    transition: color 0.2s;
}

.contact-item a:hover {
    color: #555;
}

/* "Save Contact" button */
.save-contact {
    border: none;
    outline: none;
    padding: 14px 20px;
    background: #000;
    color: #fff;
    cursor: pointer;
    font-size: 0.95rem;
    border-radius: 5px;
    transition: background 0.3s;
}

.save-contact:hover {
    background: #333;
}

/* When no person is found */
.not-found {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
}