/* RESET */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* CONTAINER */
.contact-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 89.5vh;
    padding: 2rem 1rem;
    color: #fff;
    overflow-x: hidden;
}

/* TITLE */
.contact-title {
    width: 100%;
    max-width: 1200px;
    margin: 3vw auto 2rem auto;
    padding: 0 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* GRID */
.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* CARD WRAPPER */
.team-card {
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 4;
    perspective: 1000px;
    cursor: pointer;
}

/* INNER WRAPPER */
.team-card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.6s ease;
    transform-style: preserve-3d;
    border-radius: 1rem;
    background: rgb(32, 32, 32);
}

.team-card.flipped .team-card-inner {
    transform: rotateY(180deg);
}

/* FRONT AND BACK */
.team-card-front,
.team-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 1rem;
}

.team-card-front {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.team-card-back {
    transform: rotateY(180deg);
    background: rgb(32, 32, 32);
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.back-content {
    text-align: center;
}

.back-content h2 {
    margin-bottom: 1rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.back-content p {
    color: #94a3b8;
    line-height: 1.6;
}

/* BANNER */
.banner-container {
    width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 1rem 1rem 0rem 0rem;
}

.member-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* MEMBER INFO */
.member-info {
    padding: 1rem;
    text-align: center;
    position: relative;
    margin-top: -32px;
}

.member-image-container {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #0a0b0f;
}

.member-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* TEXT */
.member-name {
    margin: 0.75rem 0 0.3rem;
    font-size: clamp(1.1rem, 3vw, 1.4rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.member-role {
    color: #94a3b8;
    font-size: clamp(0.85rem, 2vw, 1rem);
    margin-bottom: 1rem;
}

/* SOCIAL LINKS */
.social-links {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
}

.social-link {
    width: 40px;
    /* Slightly larger for a good visual impact */
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    overflow: hidden;
    /* Ensures the icon stays inside the circle */
}

.social-link:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
    /* Smooth hover animation */
}

.social-link.twitter:hover {
    color: #1DA1F2;
}

.social-link.linkedin:hover {
    color: #0077B5;
}

.social-link.telegram:hover {
    color: #0088cc;
}

.social-icon {
    width: 60%;
    /* Scale down the icon inside the container */
    height: auto;
}

.flip-hint {
    font-size: 0.8rem;
    color: #94a3b8;
    text-align: center;
    margin-top: 0.5rem;
    opacity: 0.8;
}

/* ----------------- */
/* MEDIA QUERIES */
/* ----------------- */

/* Medium Screens (<768px) */
@media (max-width: 768px) {
    .contact-title {
        margin: 2vh auto 2rem auto;
        padding: 0 1rem;
        text-align: center;
        font-size: clamp(2rem, 5vw, 3rem);
    }
}

@media (max-width: 650px) {
    .contact-title {
        margin: 2rem auto 2rem auto;
        padding: 0 1rem;
        text-align: center;
        font-size: clamp(2rem, 5vw, 3rem);
    }

    /* Set a 5rem top padding for a consistent gap above the title */
    .contact-container {
        padding: 5rem 1rem 1rem !important;
    }

    .team-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .banner-container {
        height: 100px;
    }

    .member-info {
        margin-top: -24px;
    }
}

/* Small Screens (<480px) */
@media (max-width: 480px) {
    .contact-title {
        margin: 2vh auto 2rem auto;
        padding: 0 1rem;
        text-align: center;
        font-size: clamp(2rem, 5vw, 3rem);
    }

    .member-image-container {
        width: 70px;
        height: 70px;
    }

    .team-card {
        aspect-ratio: 4 / 5;
    }
}