.introduction {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.intro-elements {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
}

.intro-text {
    color: white;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5);
    font-size: 4vw;
    font-family: "Lato", sans-serif;
    position: relative;
    z-index: 1;
    text-align: center;
    line-height: 1.5;
    white-space: pre-line;
    padding: 0 2rem;
}

.spline-resize {
    position: absolute;
    width: 80%;
    height: 80%;
    z-index: 0;
    overflow: hidden;
    transition: width 0.3s ease, height 0.3s ease;
}

.spline-scene-home {
    width: 100%;
    height: 100%;
}

@media (max-width: 1200px) {
    .spline-resize {
        width: 70%;
        height: 70%;
    }

    .intro-text {
        font-size: 4.5vw;
        padding: 0 1.5rem;
    }
}

@media (max-width: 992px) {
    .spline-resize {
        width: 60%;
        height: 60%;
    }

    .intro-text {
        font-size: 5vw;
        padding: 0 1rem;
    }
}

@media (max-width: 768px) {
    .spline-resize {
        width: 50%;
        height: 50%;
    }

    .intro-text {
        font-size: 6vw;
        padding: 0 1rem;
    }
}

@media (max-width: 576px) {
    .spline-resize {
        width: 40%;
        height: 40%;
    }

    .intro-text {
        font-size: 7vw;
        padding: 0 0.5rem;
    }
}

@media (max-width: 400px) {
    .spline-resize {
        width: 30%;
        height: 30%;
    }

    .intro-text {
        font-size: 8vw;
        padding: 0 0.3rem;
    }
}