/* Root container for the page */
.services-container {
    width: 100%;
    min-height: 100vh;
    padding: 2rem 1rem;
    position: relative;
    overflow: hidden;
}

.services-page {
    margin-top: 5rem;
    width: 100%;
    padding: 2rem 1rem;
    position: relative;
    overflow: hidden;
}

/* Page Title */
.services-title {
    margin: 0 auto 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* 
   3 columns, each 16vw wide.
   Gaps of 2rem between columns/rows.
*/
.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 16vw);
    gap: 2rem;
    justify-content: center;
    align-items: start;
    position: relative;
    z-index: 2;
    margin: 0 auto;
}

/* Each card: 16vw wide, 16vw tall, 
   with content spaced between top and bottom */
.service-card {
    width: 16vw;
    height: 16vw;
    background-color: #1e293b;
    border-radius: 8px;
    color: #cbd5e1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* push pricing & buttons to bottom */
    overflow: hidden;
    /* hide any overflow from fixed height */
}

/* Title inside each card */
.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    /* bigger text */
    color: #e2e8f0;
    line-height: 1.2;
}

/* Description text - possibly truncated/fade if too long */
.card-description {
    font-size: 1rem;
    /* bigger text */
    line-height: 1.4;
    position: relative;
    margin-bottom: 0.5rem;
}

.card-description.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* show 3 lines before fade */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card-description.collapsed::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1.4em;
    /* fade out near bottom */
    pointer-events: none;
    background: linear-gradient(to bottom,
            rgba(30, 41, 59, 0) 0%,
            #1e293b 70%);
}

/* Pricing text, placed above the buttons */
.card-pricing {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

/* Buttons row at the bottom */
.card-button-row {
    display: flex;
    gap: 0.5rem;
}

/* Larger buttons */
.card-button {
    background-color: #6366f1;
    color: #fff;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.card-button:hover {
    background-color: #4f46e5;
}

/* 
   Modal Styles
*/
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #1e293b;
    color: #cbd5e1;
    max-width: 600px;
    width: 90%;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.modal-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #e2e8f0;
}

.modal-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.modal-pricing {
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.modal-button-row {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

.modal-button {
    background-color: #6366f1;
    color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.modal-button:hover {
    background-color: #4f46e5;
}