@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Space Grotesk', sans-serif;
}

.container {
    min-height: 100vh;
    color: #fff;
    overflow: hidden;
    position: relative;
}

.back-button {
    position: fixed;
    top: 2rem;
    left: 2rem;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
}

.back-button:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(-5px);
}

/* Gradient Background */
.gradient-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 1;
}

.gradient-circle-1,
.gradient-circle-2,
.gradient-circle-3 {
    position: absolute;
    border-radius: 50%;
    filter: blur(100px);
    opacity: 0.15;
}

.gradient-circle-1 {
    background: #3b82f6;
    width: 50%;
    height: 50%;
    top: -10%;
    right: -10%;
}

.gradient-circle-2 {
    background: #8b5cf6;
    width: 40%;
    height: 40%;
    bottom: -10%;
    left: -10%;
}

.gradient-circle-3 {
    background: #06b6d4;
    width: 30%;
    height: 30%;
    bottom: 20%;
    right: 20%;
}

/* Hero Section */
.hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 2rem;
}

.hero-content {
    text-align: center;
    max-width: 800px;
}

.hero-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 12vh;
}

.hero-title {
    font-size: 5rem;
    font-weight: 700;
    background: linear-gradient(to right, #3b82f6, #8b5cf6, #06b6d4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -2px;
}

.hero-badge {
    background: rgba(59, 130, 246, 0.1);
    color: #3b82f6;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 2rem;
    font-weight: 500;
    border: 1px solid rgba(59, 130, 246, 0.2);
}

.hero-subtitle {
    font-size: 1.25rem;
    color: #94a3b8;
    margin-bottom: 2.5rem;
    line-height: 1.6;
}

/* Button Group */
.button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.launch-button,
.roadmap-button {
    background: linear-gradient(45deg, #3b82f6, #8b5cf6);
    border: none;
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.75rem;
    font-size: 1.125rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.roadmap-button {
    background: transparent;
    border: 1px solid rgba(59, 130, 246, 0.5);
}

.launch-button::before,
.roadmap-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #8b5cf6, #3b82f6);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.roadmap-button::before {
    background: linear-gradient(45deg, rgba(59, 130, 246, 0.2), rgba(139, 92, 246, 0.2));
}

.launch-button:hover::before,
.roadmap-button:hover::before {
    opacity: 1;
}

.button-text,
.button-icon {
    position: relative;
    z-index: 1;
}

/* Roadmap Section */
.roadmap {
    padding: 6rem 2rem;
    position: relative;
    z-index: 2;
}

.roadmap-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 4rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.timeline {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    gap: 2rem;
}

.timeline-item {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 2rem;
    backdrop-filter: blur(10px);
    transition: transform 0.3s ease;
}

.timeline-item:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.05);
}

.phase-badge {
    display: inline-block;
    background: rgba(59, 130, 246, 0.1);
    color: #3b82f6;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(59, 130, 246, 0.2);
}

.timeline-title {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.timeline-description {
    color: #94a3b8;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .back-button {
        top: 1rem;
        left: 1rem;
        padding: 0.5rem 1rem;
    }

    .hero-title {
        font-size: 3rem;
    }

    .hero-subtitle {
        font-size: 1.125rem;
    }

    .button-group {
        flex-direction: column;
        gap: 1rem;
    }

    .timeline {
        gap: 1.5rem;
    }

    .timeline-item {
        padding: 1.5rem;
    }
}